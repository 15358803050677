// external
import { useParams } from 'react-router-dom';

// internal
import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import {
  TraineeRegistrationChooseGroupPage,
  TraineeRegistrationChooseGuidePage,
} from 'pages';
import React from 'react';

export const TraineeRegistrationChoosePage: React.FC = () => {
  const { programSlug = '' } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  const programVariation = program?.program_details?.program_variation;
  const isGroupProgram = programVariation === 'group';

  return isGroupProgram ? (
    <TraineeRegistrationChooseGroupPage />
  ) : (
    <TraineeRegistrationChooseGuidePage />
  );
};
